/**
 * Created by henian.xu on 2018/7/23.
 *
 */

import { userMixin } from 'vmf/src/mixin';

export default {
    mixins: [userMixin],
    computed: {},
};
