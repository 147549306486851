<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="mobile"
                    label="电话"
                >
                    <el-input
                        v-model="queryFormModel.mobile"
                        placeholder="请输入电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="buyerStatus"
                    label="买家状态"
                >
                    <dictionaries-picker
                        type="buyerStatus"
                        v-model="queryFormModel.buyerStatus"
                    />
                </el-form-item>
                <el-form-item
                    prop="buyerLevelId"
                    label="会员等级"
                >
                    <el-select
                        v-model="queryFormModel.buyerLevelId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in buyerLevelList"
                            :key="item.id"
                            :label="item.levelName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <el-row
                class="second-header bc-t npa-a"
                :gutter="12"
            >
                <el-col :span="24">
                    <el-card
                        shadow="hover"
                        :body-style="{ padding: '0px' }"
                    >
                        <div
                            class="small-card"
                        >
                            <div class="icon bc-main tc-w">
                                <div class="f-icon">
                                    &#xf02c;
                                </div>
                                <div class="label">
                                    合计
                                </div>
                            </div>
                            <div class="body">
                                <div class="">
                                    会员总数
                                </div>
                                <div class="fs-more fw-bold">
                                    {{ prepaidStat.buyerNum }}
                                </div>
                            </div>
                            <div class="body">
                                <div class="">
                                    实付金额
                                </div>
                                <div class="fs-more fw-bold">
                                    {{ prepaidStat.paymentAmount || 0 }}
                                </div>
                            </div>
                            <div class="body">
                                <div class="">
                                    充值金额
                                </div>
                                <div class="fs-more fw-bold">
                                    {{ prepaidStat.prepaidAmount || 0 }}
                                </div>
                            </div>
                            <div class="body">
                                <div class="">
                                    退款金额
                                </div>
                                <div class="fs-more fw-bold">
                                    {{ prepaidStat.refundAmount || 0 }}
                                </div>
                            </div>
                            <div class="body">
                                <div class="">
                                    消费金额
                                </div>
                                <div class="fs-more fw-bold">
                                    {{ -prepaidStat.consumeAmount || 0 }}
                                </div>
                            </div>
                            <div class="body">
                                <div class="">
                                    剩余金额
                                </div>
                                <div class="fs-more fw-bold">
                                    {{ prepaidStat.purseBalance || 0 }}
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    type="index"
                    width="50"
                />
                <el-table-column
                    prop="buyerLevelName"
                    label="会员等级"
                    min-width="100"
                />
                <el-table-column
                    prop="nickName"
                    label="用户昵称"
                    min-width="100"
                />
                <el-table-column
                    label="用户头像"
                    width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.avatar"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="mobile"
                    label="电话"
                    min-width="100"
                />
                <el-table-column
                    prop="buyerStatusName"
                    label="买家状态"
                    min-width="100"
                />
                <el-table-column
                    prop="paymentAmount"
                    label="实付金额"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/stat/prepaidCardStat/buyerPrepaidOrderStatList?buyerId=' + scope.row.id)"
                        >
                            {{ scope.row.paymentAmount }}
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="prepaidAmount"
                    label="充值金额"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/stat/prepaidCardStat/buyerPrepaidOrderStatList?buyerId=' + scope.row.id)"
                        >
                            {{ scope.row.prepaidAmount }}
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="refundAmount"
                    label="退款金额"
                    min-width="100"
                />
                <el-table-column
                    prop="consumeAmount"
                    label="消费金额"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ -scope.row.consumeAmount }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="purseBalance"
                    label="剩余金额"
                    min-width="100"
                />
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'PrepaidCardStatList',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                mobile: '', // 电话
                buyerStatus: '', // 会员状态
                buyerLevelId: '', //会员等级
            },
            // 表格数据
            tableData: [],
            prepaidStat: {},
            //等级列表
            buyerLevelList: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Stat.PrepaidCardStat.prepaidCardStatList(queryData).then(json => {
                const res = json.data.data.buyerList;
                this.tableData = res.data;
                this.pagination = res.pagination;

                this.prepaidStat = json.data.data.prepaidStat;
                return Promise.resolve({ data: res });
            });
        },
        /* -- 事件 --*/
        onPaymentMode(mode) {
            if (this.queryFormModel.paymentMode === mode) return;
            this.queryFormModel.paymentMode = mode;
            this.onQuery(this.pagination);
        },
        initLevelList() {
            this.$api.Mb.BuyerLevel.select().then(json => {
                const res = json.data.data;
                this.buyerLevelList = res;
            });
        },
    },
    created() {
        this.initLevelList();
    },
};
</script>

<style lang="scss" scoped>
</style>
